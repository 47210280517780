<template>
  <vue-modal
    ref="modal"
    size="sm"
    @close="$emit('close')"
  >
    <template slot="title">
      {{ title }}
    </template>
    <template slot="body">
      <draggable
        v-model="items"
        group="items"
        handle=".handler"
        class="draggable-list"
        @start="drag=true"
        @end="drag=false"
      >
        <div
          v-for="item in items"
          :key="item[primaryKey]"
        >
          <md-icon class="handler">
            drag_handle
          </md-icon>

          <b>{{ item[nameKey] }}</b>
        </div>
      </draggable>
    </template>
    <template slot="footer">
      <md-button
        class="md-success"
        @click="saveOrder"
      >
        <md-icon>save</md-icon>
        SAVE
      </md-button>
    </template>
  </vue-modal>
</template>
<script>
import draggable from 'vuedraggable';

export default {
  components: {
    draggable,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    indexUrl: {
      type: String,
      default: null,
    },
    updateUrl: {
      type: String,
      default: null,
    },
    primaryKey: {
      type: String,
      default: null,
    },
    orderKey: {
      type: String,
      default: 'order',
    },
    nameKey: {
      type: String,
      default: 'name',
    },
  },
  data: () => ({
    items: [],
  }),
  mounted() {
    this.getItems();
  },
  methods: {
    close() {
      this.$refs.modal.close();
    },
    getItems() {
      this.request(this.indexUrl, 'get', {
        per_page: 1000,
      }, ({ data }) => {
        this.items = data.data;
      });
    },
    saveOrder() {
      let index = 1;
      const itemsSorted = [];

      // eslint-disable-next-line no-restricted-syntax
      for (const x of this.items) {
        const aux = {};
        aux[this.primaryKey] = x[this.primaryKey];
        aux[this.orderKey] = index;
        itemsSorted.push(aux);

        index += 1;
      }

      this.request(this.updateUrl, 'put', {
        items: itemsSorted,
      }, () => {
        this.fireSuccess('Items Sorted succesfully');
        this.$emit('save');
        this.close();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
  .draggable-list > div {
    padding: 5px 8px;

    &:not(:last-child) {
      border-bottom: 1px solid #ccc;
    }

    .handler {
      cursor: pointer;
      margin-right: .75em;
    }
  }
</style>
